@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #1E0E40;
  --color-bg-variant: #4a1bc1;
  --color-primary: #3cc9e8;
  --color-primary-variant: #8cf8e8;
  --color-white: #fff;
  --color-light: rgba(255,255,255, 0.6);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 94%;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #131316;
  color: var(--color-white);
  line-height: 1.7;
  background-image: url(../src/assets/bg-1.png);

  
  /* Min Size for scrolling effect */
  /*min-height: 500px;*/

   /* Create the parallax scrolling effect */
   /*background-attachment: fixed;*/
   /*background-position: center;*/
   /*background-repeat: no-repeat;*/
   /*background-size: cover;*/
   
}


/* ====================== General Style ====================== */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}


h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-ligt);
}

section > h2 {
  color: var(--color-primary); 
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light)
}

.text-light:hover {
  color: var(--color-white)
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}


.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}




/* ====================== Media Queries ====================== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 12rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section {
    margin-bottom: 35rem;
  }

  section > h2{
    margin-bottom: 2rem;
  }
}

@media(prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}