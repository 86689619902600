header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container { 
    text-align: center;
    height: 100%;
    position: relative;
}

/* ====================== CTA ====================== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


/* ====================== Header Social ====================== */

.header__social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
    position: absolute;
    left: 0rem;
    bottom: 3rem;
}

.header__social::after {
    content: '';
    width: 1px;
    height: 1rem;
    background: var(--color-primary);
}


/* ====================== Headshot ====================== */

.headshot {
    background: linear-gradient(var(--color-bg), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    border-radius: 12rem 12rem 0 0;
    margin-top: 4rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}


/* ====================== Scroll Down ====================== */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font: 0.9rem;
}


/* ====================== Media Queries (Medium) ====================== */
@media screen and (max-width: 1024px) {
    .container {
        height: 68vh;
    }
}


/* ====================== Media Queries (Larger) ====================== */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header__social, .scroll__down {
        display: none;
    }    
}