footer {
    background: var(--color-primary);
    padding: 0.1rem 0;
    text-align: center;
    font-size: center;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    margin: 0 auto 0.5rem;
}


/* ====================== Media Queries (Smaller) ====================== */
@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }
}