.skills__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.skills {
    background: var(--color-bg-variant);
    border-radius: 2rem 2rem 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.skills:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
    font-weight: bold;
}


.skills__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 2rem 2rem 2rem 2rem;
    box-shadow:  0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.skills__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.skills__list {
    padding: 2rem;
}

.skills__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.skills__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}

.skills__list p {
    font-size: 0.9rem;
}

.skills__list:nth-child(2) {
    transition-delay: 200ms;
}

.skills__list:nth-child(3) {
    transition-delay: 400ms;
}

.skills__list:nth-child(4) {
    transition-delay: 600ms;
}

.skills__list:nth-child(5) {
    transition-delay: 800ms;
}

/* ====================== Media Queries (Medium) ====================== */
@media screen and (max-width: 1024px) {
    .skills__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .skills {
        height: auto;
        gap: 2rem;
    }
}


/* ====================== Media Queries (Larger) ====================== */
@media screen and (max-width: 600px) {
    .skills__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        padding-bottom: 100rem;
    }
}