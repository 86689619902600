.experience__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

}

.experience__container > div {
    background: var(--color-bg);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}


.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 {
    text-align: center;
    color: var(--color-primary);
}

.experience__container > div h5 {
    text-align: center;
    margin-bottom: 2rem;
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
}

.experience_title > h5 {
    text-align: center;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details-icon {
    margin-top: 6px;
    width: 2rem;
    color: var(--color-primary);
}

.experience__details > div:ul:li{
    color: black;
}

/* ====================== Media Queries (Medium) ====================== */
@media screen and (max-width: 1024px) {
    
    .experience__container {
        grid-template-columns: 1fr;
        margin-bottom: 160rem;
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
}


/* ====================== Media Queries (Smaller) ====================== */
@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
        width: var(--container-width-sm);
        font-size: xx-small;
        margin-bottom: 110rem;
    }


    .experience__container > div {
        padding: 1rem 0rem;
        margin: 0 auto;
    }
}